.contact-page header {
    background-image: linear-gradient(rgba(5, 10, 35, 0.75),rgba(5, 10, 35, 0.75)), url(../../utils/images/background/hg-contact-background.jpg);
}

#contact-form {
    width: 50%;
}

#contact-form .form-control:focus,
#contact-form .form-select:focus {
    border-color: #f7931d;
    box-shadow: 0 0 0 0.25rem rgba(247, 189, 0, 0.25);
}

@media only screen and (max-width: 992px) {
    #contact-form {
        width: 75%;
    }
}

@media only screen and (max-width: 576px) {
    #contact-form {
        width: 100%;
    }
}